import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { fadeInLeftAnimation, fadeInRightAnimation, fadeInUpAnimation } from 'angular-animations';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  animations: [
    fadeInUpAnimation(),
    fadeInRightAnimation(),
    fadeInLeftAnimation()
  ]
})
export class AboutUsComponent implements OnInit, AfterViewInit {
  showAnimations = false;

  constructor() { }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    if (visible) {
      this.showAnimations = true;
    }
  }
}
