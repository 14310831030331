import { ElementRef, Output, EventEmitter } from '@angular/core';
import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
  menuPosition: any;
  sticky = false;
  openMenu = false;

  @Output() stickyChanged = new EventEmitter<boolean>();

  @Input() inView: string;

  @ViewChild('stickyMenu') menuElement: ElementRef;
  @HostListener('window:scroll', ['$event'])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    this.sticky = windowScroll >= this.menuPosition;
    this.stickyChanged.emit(this.sticky);
    this.navbarlinksActive();
  }


  constructor() { }

  ngAfterViewInit(): void {
    this.menuPosition = this.menuElement.nativeElement.offsetTop;
  }

  ngOnInit(): void {
  }

  menuChanged(item): void {
    this.openMenu = false;
    const element = document.querySelector(item);
    if (element) {
      const header = document.querySelector('#header');
      let offset = (header as HTMLElement).offsetHeight;

      if (!header.classList.contains('header-scrolled')) {
        offset -= 16;
      }

      const elementPos = element.offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
      });
    }
  }

  navbarlinksActive(): void {
    const navbarlinks = document.querySelectorAll('#navbar .scrollto');
    const position = window.scrollY + 200;
    navbarlinks.forEach(navbarlink => {
      if (!(navbarlink as HTMLElement).innerHTML) { return; }
      const findSelector = `#${(navbarlink as HTMLElement).innerHTML.toLowerCase()}`;
      const section = document.querySelector(findSelector) as HTMLElement;
      if (!section)
      {
        return;
      }
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active');
      } else {
        navbarlink.classList.remove('active');
      }
    });
  }

  toogleOpenMenu(): void {
    this.openMenu = !this.openMenu;
  }
}
