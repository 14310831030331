  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact section-bg" inViewport (inViewportAction)="onIntersection($event)">
    <div class="container">

      <div class="section-title">
        <h2>Contact</h2>
        <p>How can we help?</p>
      </div>

      <div class="row">

        <div class="col-lg-4">
          <div class="info d-flex flex-column justify-content-center" [@fadeInLeft]="showAnimations">
            
            <div class="address">
              <i class="fas fa-map-marker-alt"></i>
              <h4>Location:</h4>
              <p>Buenos Aires, Argentina</p>
            </div>

            <div class="email">
              <i class="fas fa-map-marker-alt"></i>
              <h4>Location:</h4>
              <p>General Pico, La Pampa, Argentina</p>
            </div>

            <div class="email">
              <i class="fas fa-map-marker-alt"></i>
              <h4>Location:</h4>
              <p>Oro Verde, Entre Rios, Argentina</p>
            </div>

            <div class="email">
              <i class="fas fa-envelope"></i>
              <h4>Email:</h4>
              <p>info@carpinchosolutions.com</p>
            </div>
          </div>

        </div>

        <div class="col-lg-8 mt-5 mt-lg-0">
          <!-- <form  action="https://formspree.io/f/xayaejlz" method="post" role="form" class="php-email-form" [@fadeInRight]="showAnimations"> -->
          <form [formGroup]="contactFormGroup" role="form" class="php-email-form" [@fadeInRight]="showAnimations">
            <div class="row">
              <div class="col-md-6 form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required formControlName="nameCtrl">
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required formControlName="emailCtrl">
              </div>
            </div>
            <div class="form-group mt-3">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required formControlName="subjectCtrl">
            </div>
            <div class="form-group mt-3">
              <textarea class="form-control" name="message" rows="5" placeholder="Message" required formControlName="messageCtrl"></textarea>
            </div>
            <div class="text-center">
              <button type="submit" (click)="sendContact()" [disabled]="sending">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="sending"></span>
                {{ sending ? 'Sending...' : 'Send Message'}}
              </button>
            </div>
            <div class="alert alert-success d-flex align-items-center" role="alert" *ngIf="successSend">
              <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
              <div>
                Successfully sended
              </div>
            </div>
            <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="errorSend">
              <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
              <div>
                Error: {{ sendindErrorMsg }}
              </div>
            </div>
    
        </form>

        </div>

      </div>

    </div>
  </section><!-- End Contact Section -->