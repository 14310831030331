import { AfterViewInit, Component, OnInit } from '@angular/core';
import { zoomInAnimation, fadeInUpAnimation } from 'angular-animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    zoomInAnimation(),
    fadeInUpAnimation()
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit {

  showed = false;

  constructor() { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    if (visible) {
      this.showed = true;
    }
  }
}
