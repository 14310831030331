<header id="header" class="d-flex align-items-center" [ngClass]="sticky ? 'fixed-top': ''" #stickyMenu>
    <div class="container d-flex align-items-center justify-content-between">

      <div class="logo">
        <a href="index.html"><img src="assets/img/mini-logo.png" alt="" class="img-fluid"></a>
      </div>

      <nav id="navbar" class="navbar" [ngClass]="openMenu ? 'navbar-mobile' : '' " >
        <div>
          <ul>
            <li><a class="nav-link scrollto" (click)="menuChanged('#hero')">Home</a></li>
            <li><a class="nav-link scrollto" (click)="menuChanged('#about')">About</a></li>
            <li><a class="nav-link scrollto" (click)="menuChanged('#services')">Services</a></li>
            <li><a class="nav-link scrollto" (click)="menuChanged('#contact')">Contact</a></li>
          </ul>

        </div>  
        <i class="fas fa-bars mobile-nav-toggle" *ngIf="!openMenu" (click)="toogleOpenMenu()"></i>
        <i class="fas fa-times mobile-nav-toggle  bi-x" *ngIf="openMenu" (click)="toogleOpenMenu()"></i>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->