import { AfterViewInit, ElementRef, HostListener, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { fadeInUpAnimation } from 'angular-animations';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  animations: [
    fadeInUpAnimation()
  ]
})
export class ServicesComponent implements OnInit {
  sectionPosition: any;
  sectionHeight: any;
  scrolledMargin = false;
  showAnimations = false;

  constructor() { }

  ngOnInit(): void {
  }

  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    if (visible) {
      this.showAnimations = true;
    }
  }
}
