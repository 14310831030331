import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  scrolled = false;
  title = 'Carpincho Solutions';

  constructor() { }

  ngOnInit(): void {
  }

  stickyChanged(sticky: boolean): void {
    this.scrolled = sticky;
  }
}
