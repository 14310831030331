import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactMail } from '../models/contact-mail.model';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http: HttpClient) { }

  sendContactMail(email: ContactMail): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post('https://formspree.io/f/xayaejlz',
        { name: email.name, replyto: email.email, message: email.messagge, subject: email.subject },
        { headers });
  }

}
