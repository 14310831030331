import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { fadeInRightAnimation, fadeInLeftAnimation, fadeInUpAnimation } from 'angular-animations';
import { ContactMail } from 'src/app/models/contact-mail.model';
import { MailService } from '../../services/mail.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [
    fadeInRightAnimation(),
    fadeInLeftAnimation(),
    fadeInUpAnimation()
  ]
})
export class ContactComponent implements OnInit {
  showAnimations = false;
  successSend = false;
  errorSend = false;
  sendindErrorMsg: string;
  sending = false;
  contactFormGroup: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private mailSvc: MailService
  ) { }

  ngOnInit(): void {
    this.contactFormGroup = this._formBuilder.group({
      nameCtrl: [''],
      emailCtrl: [''],
      subjectCtrl: [''],
      messageCtrl: ['']
    });
  }

  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    if (visible) {
      this.showAnimations = true;
    }
  }

  sendContact(): void {
    if (this.contactFormGroup.invalid) {
      return;
    }

    this.sending = true;
    const mail: ContactMail = {
      name: this.contactFormGroup.value.nameCtrl,
      email: this.contactFormGroup.value.emailCtrl,
      subject: this.contactFormGroup.value.subjectCtrl,
      messagge: this.contactFormGroup.value.messageCtrl,
    };
    this.mailSvc.sendContactMail(mail).toPromise()
      .then(data => {
        this.successSend = true;
        this.contactFormGroup = this._formBuilder.group({
          nameCtrl: [''],
          emailCtrl: [''],
          subjectCtrl: [''],
          messageCtrl: ['']
        });

        setTimeout(() => {
          this.successSend = false;
        }, 2000);
      })
      .catch(err => {
        if (err.error.error) {
          this.sendindErrorMsg = err.error.error;
        } else {
          this.sendindErrorMsg = 'Cannot send the contact info. Please try again later.';
        }
        this.errorSend = true;

        setTimeout(() => {
          this.errorSend = false;
        }, 2000);
      })
      .finally(() => this.sending = false);
  }
}
