<!-- ======= About Us Section ======= -->
<section id="about" class="about" inViewport (inViewportAction)="onIntersection($event)">
    <div class="container">

      <div class="section-title" [@fadeInUp]="showAnimations">
        <h2>About us</h2>
        <p>From Argentina to the world</p>
      </div>

      <div class="row">
        <div class="col-lg-6" [@fadeInLeft]="showAnimations">
          <div class="image">
            <img src="assets/img/about.jpg" class="img-fluid" alt="">
          </div>
        </div>
        <div class="col-lg-6" [@fadeInRight]="showAnimations">
          <div class="content pt-4 pt-lg-0 pl-0 pl-lg-3 ">
            <h3></h3>
            <p class="fst-italic">
              We build software solutions with a great team of senior software engineers. We have worked togheter as a team on different projects since 2014.
            </p>

            <ul>
              <li><i class="bx bx-check-double"></i> Strong software architecture solutions</li>
              <li><i class="bx bx-check-double"></i> Cloud and on premmise projects</li>
            </ul>
            <p>
              We believe in the power of teamwork. We have a team that is complemented by the different specialties of its members.
              Our team work with agile methodologies always focused on the value and good design of the solutions we develop.
              All our members have many years of work and training.
            </p>
            <!-- <p>
              Creemos en la fuerza del trabajo en equipo. Tenemos un equipo que se complementa por las distintas especialidades de sus miembros.
              Trabajamos con metodologías ágiles siempre centrados en el valor y el buen diseño de las soluciones que desarrollamos.
              Todos nuestros miembros cuentan con muchos años de trabajo y formación.
            </p> -->
          </div>
        </div>
      </div>

    </div>
  </section><!-- End About Us Section -->