<section id="services" class="services" #sectionService inViewport (inViewportAction)="onIntersection($event)">
  <div class="container">

    <div class="section-title" [@fadeInUp]="showAnimations">
      <h2>Services</h2>
      <p>We aim to build a long term relationship with our clients by delivering exceptional outcomes and value</p>
    </div>

    <div class="row">
      <div class="col-sm">
        <div class="icon-box mt-5" [@fadeInUp]="showAnimations">
          <i class="fas fa-laptop-code"></i>
          <h4>Development</h4>
          <p>Custom Software Development</p>
          <p>Fixed Scope / T&M</p>
          <p>Staff Augmentation</p>
          <p>Offshore Development</p>
        </div>
      </div>
      <div class="col-sm">
        <!-- asnimation delay 100 -->
        <div class="icon-box mt-5" [@fadeInUp]="showAnimations">
          <i class="fas fa-object-group"></i>
          <h4>UI & UX Design</h4>
          <p>Consulting and Analysis</p>
          <p>Research and Strategy</p>
          <p>User Interface Design</p>
          <p>Technical development</p>
        </div>
      </div>
      <div class="col-sm">
        <!-- data-aos-delay="200" -->
        <div class="icon-box mt-5" [@fadeInUp]="showAnimations">
          <i class="fas fa-flask"></i>
          <h4>QA & Testing</h4>
          <p>Test Case Definition and Execution</p>
          <p>Test Automation</p>
          <p>Non-functional Testing</p>
          <p>Mobile Testing</p>
        </div>
      </div>
      <div class="col-sm">
        <!-- data-aos-delay="300" -->
        <div class="icon-box mt-5" [@fadeInUp]="showAnimations" >
          <i class="fas fa-crosshairs"></i>
          <h4>Consulting</h4>
          <p>Technological Strategy & Architectural definition</p>
          <p>BPR (Business Process Redesign)</p>
          <p>Functional Specification</p>
          <p>Scrum Developer Training</p>
        </div>
      </div>
    </div>
  </div>
</section><!-- End Services Section -->